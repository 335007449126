<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? (Customer__otsname ? Customer__otsname : Customer__title) : ''" help-page-component="AddBuyer"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <BuyerAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="add"
                    v-if="addMode && newBuyerUnsaved"
                />
                <BuyerAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="update"
                    v-if="updateMode && updatedBuyerUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="buyerProfileForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                            multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <BuyerProfileGeneralInfo v-if="loader"></BuyerProfileGeneralInfo>
                                    <template v-if="!loader">
                                        <v-layout row pt-6 v-if="updateMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveBuyer') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                        :items="allBuyers"
                                                        :loading="loadingAnotherBuyer"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        item-value="Customer.id"
                                                        item-text="Customer.title"
                                                        solo
                                                        @click="loadingAnotherBuyer = true"
                                                        @blur="loadingAnotherBuyer = false"
                                                        @change="loadAnotherBuyer($event)"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.name') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :label="Customer__title ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                        :value="Customer__title"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Customer__title = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Customer__removed == 1">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.status') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 class="red--text lighten-1">{{ $t('message.suspended') }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.otsShortName') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :label="Customer__otsname ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                        :value="Customer__otsname"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Customer__otsname = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.otherRelatedName') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :value="Customer__name2"
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Customer__name2 = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.taxNumber') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center">
                                                <v-select
                                                        :label="$t('message.type')"
                                                        :placeholder="$t('message.na')"
                                                        :items="taxTypes"
                                                        class="field-width-150"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Company__taxno_type"
                                                />
                                                <v-text-field
                                                        :label="$t('message.number')"
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Company__gstno"
                                                        autocomplete="new-password"
                                                        class="force-text-left ml-3"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__gstno = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.address1') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :label="Company__address1 ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                        :value="Company__address1"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__address1 = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.address2') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Company__address2"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__address2 = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.address3') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Company__address3"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__address3 = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.city') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :label="Company__city ? '' : $t('message.required')"
                                                        :rules="noCityCountries(Company__country_id) ? [...validationRules.plainText] : [...validationRules.required, ...validationRules.plainText]"
                                                        :value="Company__city"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__city = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.postcode') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Company__postcode"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__postcode = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.stateProvince') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainText]"
                                                        :value="Company__state"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__state = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.country') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                        :items="allCountries"
                                                        :label="Company__country_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        autocomplete="new-password"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Country.name"
                                                        item-value="Country.id"
                                                        solo
                                                        v-model="Company__country_id"
                                                        @change="filterCreditLimitCurrencies"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.otsOverrideForAddress') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-checkbox
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Company__override_ots_country"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.officeTelephone') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.phoneNumber]"
                                                        :value="Company__tel"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__tel = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.website') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.url]"
                                                        :value="Company__website"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        hide-details="auto"
                                                        solo
                                                        @change="Company__website = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.salesColleague') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                        :items="allSalesColleagues"
                                                        :label="Customer__salescontact_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        item-text="Salesperson.name"
                                                        item-value="Salesperson.id"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Customer__salescontact_id"
                                                >
                                                    <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-avatar>
                                                                <img :src="data.item.User.avatar ? data.item.User.avatar : '/static/avatars/appic_user.jpg'">
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="data.item.Salesperson.name"></v-list-item-title>
                                                                <v-list-item-subtitle v-html="data.item.Country.name"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.salesTerritory') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-select
                                                        :items="territories"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Territory.title"
                                                        item-value="Territory.id"
                                                        solo
                                                        v-model="Customer__territory_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.marketSegments') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                        :items="allProductSegments"
                                                        :label="Customer__marketsegment_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        item-text="Marketsegment.title"
                                                        item-value="Marketsegment.id"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Customer__marketsegment_id"
                                                />
                                                <v-autocomplete
                                                        :items="allProductSegments"
                                                        clearable
                                                        dense
                                                        item-text="Marketsegment.title"
                                                        item-value="Marketsegment.id"
                                                        hide-details
                                                        label="#2"
                                                        solo
                                                        v-model="Customer__marketsegment2_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.unableToAccessAppic') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <template v-if="allowedUserRoles.includes(User.role)">
                                                    <v-checkbox
                                                            :label="$t('message.yes')"
                                                            :true-value="1"
                                                            :false-value="null"
                                                            class="mt-0"
                                                            dense
                                                            hide-details="auto"
                                                            v-model="Customer__no_ots_access"
                                                    />
                                                </template>
                                                <template v-else>{{ Customer__no_ots_access == 1 ? $t('message.yes') : $t('message.no') }}</template>
                                            </v-flex>
                                        </v-layout>
    <!--                                    <v-layout row pt-3 v-if="false">-->
    <!--                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.otsUserId') }}</v-flex>-->
    <!--                                        <v-flex xs6 lg3 md5 py-1>-->
    <!--                                            <v-text-field-->
    <!--                                                :rules="[addMode ? rules.userid : true]"-->
    <!--                                                :value="Customer__userid"-->
    <!--                                                autocomplete="off"-->
    <!--                                                background-color="grey lighten-3"-->
    <!--                                                class="force-text-left"-->
    <!--                                                clearable-->
    <!--                                                dense-->
    <!--                                                hide-details="auto"-->
    <!--                                                ref="useridField"-->
    <!--                                                solo-->
    <!--                                                @change="Customer__userid = $event"-->
    <!--                                            >-->
    <!--                                                <template v-slot:append>-->
    <!--                                                    <v-tooltip bottom>-->
    <!--                                                        <template v-slot:activator="{ on }">-->
    <!--                                                            <v-icon-->
    <!--                                                                class="orange&#45;&#45;text text&#45;&#45;darken-3"-->
    <!--                                                                v-on="on"-->
    <!--                                                                @click="getNewOtsBuyerId"-->
    <!--                                                            >mdi-download</v-icon>-->
    <!--                                                        </template>-->
    <!--                                                        <span>{{ $t('message.getNewOtsBuyerId') }}</span>-->
    <!--                                                    </v-tooltip>-->
    <!--                                                </template>-->
    <!--                                            </v-text-field>-->
    <!--                                        </v-flex>-->
    <!--                                        <v-flex md1 pa-0 mt-2 v-if="loading.newOtsBuyerId">-->
    <!--                                            <v-progress-circular-->
    <!--                                                color="orange"-->
    <!--                                                indeterminate-->
    <!--                                                size="24"-->
    <!--                                            ></v-progress-circular>-->
    <!--                                        </v-flex>-->
    <!--                                    </v-layout>-->
    <!--                                    <v-layout row v-if="false">-->
    <!--                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.otsPassword') }}</v-flex>-->
    <!--                                        <v-flex xs6 lg3 md5 py-1>-->
    <!--                                            <v-text-field-->
    <!--                                                :rules="[addMode ? rules.password : true]"-->
    <!--                                                :value="Customer__password"-->
    <!--                                                autocomplete="off"-->
    <!--                                                background-color="grey lighten-3"-->
    <!--                                                class="force-text-left"-->
    <!--                                                clearable-->
    <!--                                                dense-->
    <!--                                                hide-details="auto"-->
    <!--                                                ref="passwordField"-->
    <!--                                                solo-->
    <!--                                                @change="Customer__password = $event"-->
    <!--                                            >-->
    <!--                                                <template v-slot:append>-->
    <!--                                                    <v-tooltip bottom>-->
    <!--                                                        <template v-slot:activator="{ on }">-->
    <!--                                                            <v-icon-->
    <!--                                                                class="orange&#45;&#45;text text&#45;&#45;darken-3"-->
    <!--                                                                v-on="on"-->
    <!--                                                                @click="getNewOtsBuyerPassword"-->
    <!--                                                            >mdi-download</v-icon>-->
    <!--                                                        </template>-->
    <!--                                                        <span>{{ $t('message.getNewOtsBuyerPassword') }}</span>-->
    <!--                                                    </v-tooltip>-->
    <!--                                                </template>-->
    <!--                                            </v-text-field>-->
    <!--                                        </v-flex>-->
    <!--                                    </v-layout>-->
    <!--                                    <v-layout row v-if="false">-->
    <!--                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=""></v-flex>-->
    <!--                                        <v-flex xs6 lg3 md5 py-1>-->
    <!--                                            <v-btn-->
    <!--                                                color="green lighten-3"-->
    <!--                                                small-->
    <!--                                                @click="openSendLoginInfoDialog()"-->
    <!--                                                v-if="addMode == false"-->
    <!--                                            >{{ $t('message.sendLoginInfoToBuyer') }}</v-btn>-->
    <!--                                        </v-flex>-->
    <!--                                    </v-layout>-->
                                        <v-layout row pt-5>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="font-weight-bold">{{ $t('message.contacts') }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <ContactListNew
                                                    :updateMode="this.updateMode"
                                                    :company-type="this.companyType"
                                                    :validate.sync="validateContacts"
                                                    @items-invalid="setItemsValidity(false)"
                                                    @items-valid="setItemsValidity(true)"
                                                    @reload-contact-list="reloadContactList()"
                                            />
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.operations') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.portOfDischarge') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                        :items="allShippingDischargePorts"
                                                        clearable
                                                        dense
                                                        item-value="Shippingport.id"
                                                        item-text="Shippingport.title"
                                                        hide-details="auto"
                                                        label="#1"
                                                        solo
                                                        v-model="Customer__shippingport_id"
                                                >
                                                    <template v-slot:selection="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                </v-autocomplete>
                                                <v-autocomplete
                                                        :items="allShippingDischargePorts"
                                                        clearable
                                                        dense
                                                        item-value="Shippingport.id"
                                                        item-text="Shippingport.title"
                                                        hide-details="auto"
                                                        label="#2"
                                                        solo
                                                        v-model="Customer__shippingport2_id"
                                                >
                                                    <template v-slot:selection="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.buyerSpecificRequests') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-textarea
                                                        :rules="[...validationRules.paragraph]"
                                                        :value="Customer__doc_setting"
                                                        clearable
                                                        dense
                                                        solo
                                                        hide-details="auto"
                                                        @change="Customer__doc_setting = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3 v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.taxId') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-textarea
                                                    :value="Company__gstno"
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    autocomplete="new-password"
                                                    clearable
                                                    class="force-text-left"
                                                    hide-details="auto"
                                                    rows="3"
                                                    solo
                                                    @change="Company__gstno = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.uscc') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :value="Company__uscc"
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    autocomplete="new-password"
                                                    clearable
                                                    class="force-text-left"
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__uscc = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.directServiceSales') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="directSalesPaymentTerms"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    label="#1"
                                                    solo
                                                    v-model="Customer__paymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                            <v-autocomplete
                                                    :items="directSalesPaymentTerms"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    label="#2"
                                                    solo
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                            <v-autocomplete
                                                    :items="directSalesPaymentTerms"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    label="#3"
                                                    solo
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.warehouseSales') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="warehouseSalesPaymentTerms"
                                                    :readonly="$can('update','BuyerPaymentTerm') == false"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    label="#1"
                                                    solo
                                                    v-model="Customer__ls_paymentstatement_id "
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                            <v-autocomplete
                                                    :items="warehouseSalesPaymentTerms"
                                                    :readonly="$can('update','BuyerPaymentTerm') == false"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    label="#2"
                                                    solo
                                                    v-if="false"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="$can('update','BuyerCreditLimit')">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.currentApprovedCredit') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center">
                                            <v-text-field
                                                    :value="CreditLimit__amount"
                                                    :rules="[...validationRules.amount]"
                                                    :placeholder="'0'"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    type="number"
                                                    min="0"
                                                    solo
                                                    @change="CreditLimit__amount = $event"
                                            />
                                            <v-select
                                                    :items="creditLimitCurrencies"
                                                    :placeholder="$t('message.cur')"
                                                    class="field-width-50 ml-2"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Currency.code"
                                                    item-value="Currency.id"
                                                    solo
                                                    v-model="CreditLimit__currency_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="updateMode == true">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.customerNumber') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    :value="Company__GPCustomerNumber"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__GPCustomerNumber = $event"
                                                    v-if="!updateMode"
                                            />
                                            <span v-if="updateMode">{{ Company__GPCustomerNumber }}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.alternateAddresses') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <AddressesList :updateMode="updateMode"></AddressesList>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.map') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-alert
                                            :value="cannotGeoLocate"
                                            color="warning"
                                            border="left"
                                            class="mt-3"
                                            dense
                                            icon="warning"
                                            style="height: unset; margin-bottom: 0px;"
                                            text
                                            width="auto"
                                    >{{ $t('message.errors.cannotGeoLocateBuyer') }}</v-alert>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.latitude') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.number]"
                                                    :value="Company__latitude"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__latitude = $event"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.longitude') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.number]"
                                                    :value="Company__longitude"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__longitude = $event"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg6 md5 py-1 offset-xs-6 offset-lg-2 offset-md-3>
                                            <div class="v-input force-text-left v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed">
                                                <div class="v-input__control">
                                                    <div class="v-input__slot">
                                                        <div class="v-text-field__slot">
                                                            <GmapAutocomplete @place_changed="setPlace"></GmapAutocomplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-flex>
                                        <v-flex xs3 lg1 md2 py-1>
                                            <v-btn @click="addMarker()">{{ $t('message.add') }}</v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs6 lg2 md3 pl-5 py-1></v-flex>
                                        <v-flex xs6 lg10 md9 py-1>
                                            <GmapMap
                                                    :center="map.center"
                                                    :zoom="17"
                                                    style="width: 100%; height: 400px"
                                                    ref="buyerLocation"
                                            >
                                                <GmapMarker
                                                        :key="index"
                                                        :position="m.position"
                                                        v-for="(m, index) in map.markers"
                                                        @click="center = m.position"
                                                />
                                            </GmapMap>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_customer')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card elevation="0"class="pt-3">
                                        <v-tabs
                                            background-color="grey lighten-3"
                                        >
                                            <v-tab key="email" href="#tab-email" @click="updateHistoryKey()"><v-icon small class="pr-3">send</v-icon>{{ $t('message.notifications') }}</v-tab>
                                            <v-tab key="audit_log" href="#tab-audit_log" @click="updateHistoryKey()"><v-icon small class="pr-3">list</v-icon>{{ $t('message.auditLog') }}</v-tab>
                                            <v-tab-item key="email" value="tab-email">
                                                <NotificationsLog asset="customers" :asset_id="Customer__id" :key="'nl' + historyKey"></NotificationsLog>
                                            </v-tab-item>
                                            <v-tab-item key="audit_log" value="tab-audit_log">
                                                <DocumentHistory asset="customers" :asset_id="Customer__id" :key="'dh' + historyKey"></DocumentHistory>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            v-if="false"
                                            @click.stop="previewDialog = true"
                                    >{{ $t('message.previewProfile') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="addBuyer"
                                            v-if="addMode == true && $can('create','Buyer')"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.reset"
                                            @click="resetBuyerForm"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode == true && $can('create','Buyer')"
                                    ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="saveBuyer"
                                            v-if="addMode == false && $can('update','Buyer')"
                                    >{{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.suspend"
                                            class="ml-3"
                                            color="error"
                                            small
                                            @click="suspendBuyer(Customer__id)"
                                            v-if="addMode == false && $can('update','Buyer') && Customer__removed != 1"
                                    >{{ $t('message.suspend') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.convert"
                                            class="ml-3"
                                            small
                                            @click="openConvertToSupplierDialog"
                                            v-if="updateMode == true && $can('convert','Buyer')"
                                    >{{ $t('message.copySupplier') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
                overlay=false
                scrollable
                transition="dialog-bottom-transition"
                v-model="previewDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.buyerPreview') }}</v-card-title>
                <v-card-text class="pa-3"></v-card-text>
                <v-card-actions class="pr-3 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="previewDialog = false"
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.add"
                            class="ml-3"
                            color="info"
                            small
                            @click="addBuyer"
                            v-if="addMode == true"
                    >{{ $t('message.addBuyer') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.save"
                            class="ml-3"
                            color="info"
                            small
                            @click="saveBuyer"
                            v-if="addMode == false"
                    >{{ $t('message.saveChanges') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                overlay=false
                persistent
                transition="dialog-bottom-transition"
                v-model="sendLoginInfoDialog"
                width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendLoginInfoToBuyer') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0 width-1-pct"></th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="buyerContacts.length > 0">
                                        <tr v-for="contact in buyerContacts" v-bind:key="contact.Contact.id">
                                            <td>
                                                <v-checkbox
                                                    :value="contact.Contact.email"
                                                    class="mt-1"
                                                    dense
                                                    hide-details="auto"
                                                    v-model="selectedBuyerContacts"
                                                    v-if="contact.Contact.email != null && contact.Contact.email != ''"
                                                ></v-checkbox>
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                            <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">
                                                <span v-if="contact.Contact.email != null && contact.Contact.email != ''">{{ contact.Contact.email }}</span>
                                                <span class="red--text" v-else>{{ $t('message.pleaseProvide') }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="6">{{ $t('message.noContactsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <v-row v-if="selectedBuyerContacts.length > 0">
                        <v-col cols="12" class="d-flex flex-row align-center">
                            <v-checkbox
                                    :value="Salescontact__bcc"
                                    class="ml-2 mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Salescontact__bcc"
                            ></v-checkbox>
                            {{ $t('message.bccSalesColleague') }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="sendLoginInfoDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.send"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendLoginInfoToBuyer()"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                overlay=false
                scrollable
                transition="dialog-bottom-transition"
                width="600px"
                v-model="convertToSupplerDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.copySupplier') + ': ' + Customer__title  }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-alert dense>{{ $t('message.confirmations.continueConvertBuyerToSupplierAction') }}</v-alert>
                    <template v-if="sameNameSuppliers.length > 0">
                        <div class="mx-4">
                            <h5>{{ $t('message.sameNameSuppliers') }}</h5>
                            <div v-for="supplier in sameNameSuppliers" :key="supplier.Supplier.id" class="font-md">{{ supplier.Supplier.name }}</div>
                        </div>
                    </template>
                </v-card-text>
                <v-card-actions class="pr-3 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="closeConvertToSupplierDialog"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.add"
                            class="ml-3"
                            color="info"
                            small
                            @click="convertBuyerToSupplier"
                            v-if="updateMode == true && $can('convert','Buyer')"
                    >{{ $t('message.copy') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AlertMissingValues
                :alert_message="this.dialogs.error_message"
                :missing_values="this.errors.missing_values"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        >
        </AlertMissingValues>
    </div>
</template>
<script>
    import { api } from "../../../api";
    import {mapGetters, mapActions, mapState} from "vuex";
    // import AlertMissingValues from "../../../components/Appic/AlertMissingValues";
    // import ContactList from "../../../components/Appic/ContactList";
    import {mapFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'
    import {mapFields} from "vuex-map-fields";
    // import BuyerPreview from "../../../components/Appic/BuyerPreview";
    // import DocumentHistory from "../../../components/Appic/DocumentHistory";
    // import CreditLimits from "../../../components/Appic/CreditLimits";
    // import BuyerAlert from "../../../components/Appic/BuyerAlert";
    import router from "../../../router";
    // import AddressesList from "Components/Appic/AddressesList";
    // import NotificationsLog from "Components/Appic/NotificationsLog";

    import {gmapApi} from 'vue2-google-maps';
    import Vue from "vue";
    import * as VueGoogleMaps from "vue2-google-maps";
    import {store} from "@/store/store";

    import {mapOrder, numberFormat} from "Helpers/helpers"


    const AddressesList = () => import("Components/Appic/AddressesList");
    const AlertMissingValues = () => import("Components/Appic/AlertMissingValues");
    const BuyerAlert = () => import("Components/Appic/BuyerAlert");
    const BuyerPreview = () => import("Components/Appic/BuyerPreview");
    // const ContactList = () => import("../../../components/Appic/ContactList");
    const ContactListNew = () => import('Components/Appic/ContactListNew');
    // const CreditLimits = () => import("Components/Appic/CreditLimits");
    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const NotificationsLog = () => import("Components/Appic/NotificationsLog");
    const BuyerProfileGeneralInfo = () => import("Components/Appic/Loading/BuyerProfileGeneralInfo");

    Vue.use(VueGoogleMaps, {
        load: {
            key: store.state.appic.user.current.google ? store.state.appic.user.current.google.key : null,
            libraries: 'places',
        },
        installComponents: true
    })

    export default {
        components: {
            BuyerProfileGeneralInfo,
            AddressesList,
            AlertMissingValues,
            BuyerAlert,
            BuyerPreview,
            // CreditLimits,
            // ContactList,
            ContactListNew,
            DocumentHistory,
            NotificationsLog
        },
        name: "AddBuyer",
        props: ["buyerId"],
        title: "",
        data() {
            return {
                allowedUserRoles: ['ROLE_ADMINISTRATOR','ROLE_SUPERADMIN','ROLE_OPERATIONS_STAFF','ROLE_OPERATIONS_MANAGER'],
                cannotGeoLocate: false,
                companyType: 'buyer',
                buyerContacts: [],
                convertToSupplerDialog: false,
                creditLimitCurrencies: [],
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                historyKey: '',
                enterOtsBuyerIdManually: false,
                errors: {
                    missing_values: []
                },
                google: gmapApi,
                loader: false,
                loading: {
                    add: false,
                    convert: false,
                    newOtsBuyerId: false,
                    reset: false,
                    send: false,
                    suspend: false,
                    save: false
                },
                map: {
                    center: {lat: 0, lng: 0},
                    currentPlace: null,
                    markers: [],
                    places: []
                },
                loadingAnotherBuyer: false,
                panel: [0],
                pageKey: 0,
                previewDialog: false,
                rules: {
                    address: v => !!v || this.$t('message.required'),
                    city: v => !!v || this.$t('message.required'),
                    country: v => !!v || this.$t('message.required'),
                    name: v => !!v || this.$t('message.required'),
                    otsName: v => !!v || this.$t('message.required'),
                    password: v => !!v || this.$t('message.errors.pleaseGenerateUserId'),
                    productSegment: v => !!v || this.$t('message.required'),
                    salesColleague: v => !!v || this.$t('message.required'),
                    userid: v => !!v || this.$t('message.errors.pleaseGenerateUserPassword')
                },
                Salescontact__bcc: true,
                sameNameSuppliers: [],
                selectedBuyerContacts: [],
                sendLoginInfoDialog: false,
                taxTypes: [
                    {value: 'EORI No.', text: 'EORI No.'},
                    {value: 'NPWP No.', text: 'NPWP No.'},
                    {value: 'Tax ID.', text: 'Tax ID.'},
                    {value: 'TIN', text: 'TIN'},
                    {value: 'USCC', text: 'USCC'},
                    {value: 'VAT', text: 'VAT'}
                ],
                validContacts: false,
                validateContacts: false,
                validForm: true
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('buyer',{
                Company__id: 'Company.id',
                Company__address1: 'Company.address1',
                Company__address2: 'Company.address2',
                Company__address3: 'Company.address3',
                Company__city: 'Company.city',
                Company__country_id: 'Company.country_id',
                Company__email: 'Company.email',
                Company__fax: 'Company.fax',
                Company__GPCustomerNumber: 'Company.GPCustomerNumber',
                Company__gstno: 'Company.gstno',
                Company__latitude: 'Company.latitude',
                Company__longitude: 'Company.longitude',
                Company__name: 'Company.name',
                Company__override_ots_country: 'Company.override_ots_country',
                Company__postcode: 'Company.postcode',
                Company__state: 'Company.state',
                Company__taxno_type: 'Company.taxno_type',
                Company__tel: 'Company.tel',
                Company__uscc: 'Company.uscc',
                Company__website: 'Company.website',
                Country__code: 'Country.code',
                CreditLimit__amount: 'CreditLimit.amount',
                CreditLimit__currency_id: 'CreditLimit.currency_id',
                CreditLimit__id: 'CreditLimit.cid',
                Customer__company_id: 'Customer.company_id',
                Customer__cs_paymentstatement_id: 'Customer.cs_paymentstatement_id',
                Customer__doc_setting: 'Customer.doc_setting',
                Customer__id: 'Customer.id',
                Customer__ls_paymentstatement_id: 'Customer.ls_paymentstatement_id',
                Customer__marketsegment_id: 'Customer.marketsegment_id',
                Customer__marketsegment2_id: 'Customer.marketsegment2_id',
                Customer__name2: 'Customer.name2',
                Customer__no_ots_access: 'Customer.no_ots_access',
                Customer__otsname: 'Customer.otsname',
                Customer__password: 'Customer.password',
                Customer__paymentstatement_id: 'Customer.paymentstatement_id',
                Customer__removed: 'Customer.removed',
                Customer__salescontact_id: 'Customer.salescontact_id',
                Customer__shippingport_id: 'Customer.shippingport_id',
                Customer__shippingport2_id: 'Customer.shippingport2_id',
                Customer__territory_id: 'Customer.territory_id',
                Customer__title: 'Customer.title',
                Customer__userid: 'Customer.userid'
            },'statePrefix'),
            ...mapFields('buyer',{
                newBuyerSaved: 'newBuyerSaved',
                newBuyerUnsaved: 'newBuyerUnsaved',
                updatedBuyerSaved: 'updatedBuyerSaved',
                updatedBuyerUnsaved: 'updatedBuyerUnsaved',
            }),
            ...mapGetters('buyer',{
                allBuyers: 'allBuyers'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries'
            }),
            ...mapGetters('currency', {
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('paymentterm', {
                allBuyerPaymentTerms: 'allBuyerPaymentTerms'
            }),
            ...mapGetters('productsegment',{
                allProductSegments: 'allProductSegments'
            }),
            ...mapGetters('salescolleague', {
                allSalesColleagues: 'allSalesColleagues'
            }),
            ...mapGetters('shippingport', {
                allShippingDischargePorts: 'allShippingDischargePorts'
            }),
            ...mapGetters('user',{
                User: 'User'
            }),
            ...mapGetters([
                'territories',
                'validationRules'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_customer')
            },
            //TODO this is temporary remove later when casl is applied
            canSuspendActivate () {
                return ['vanjoe@apptimber.com'].includes(this.User.email)
            },
            directSalesPaymentTerms(){
                return this.allBuyerPaymentTerms.filter((d)=>d.Paymentterm.localsales_only != 1 )
            },
            warehouseSalesPaymentTerms(){
                // return this.allBuyerPaymentTerms.filter((d)=>d.Paymentterm.localsales_only == 1)
                const order = [4294, 4304, 4464, 4463, 4435, 4442]
                let paymentTerms = this.allBuyerPaymentTerms.filter((d)=>d.Paymentterm.localsales_only == 1)
                mapOrder(paymentTerms, order, 'Paymentterm.id')
                return paymentTerms
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_customer')
            },
        },
        methods: {
            ...mapActions('buyer',{
                createBuyer: 'createBuyer',
                getAllActiveBuyers: 'getAllActiveBuyers',
                getBuyerToUpdateById: 'getBuyerToUpdateById',
                resetCurrentBuyer: 'resetCurrentBuyer',
                resetAllBuyers: 'resetAllBuyers',
                sendOtsLoginInfoToBuyer: 'sendOtsLoginInfoToBuyer',
                suspendBuyerById: 'suspendBuyerById',
                updateBuyer: 'updateBuyer'
            }),
            ...mapActions('contact',{
                getActiveContactsByCompany: 'getActiveContactsByCompany',
                resetAllContacts: 'resetAllContacts'
            }),
            ...mapActions('country',{
               getAllCountries: 'getAllCountries'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('customeraddress',{
                getActiveAddressesByBuyer: 'getActiveAddressesByBuyer',
                resetAllAddresses: 'resetAllAddresses'
            }),
            ...mapActions('paymentterm',{
                getAllPaymentTerms: 'getAllPaymentTerms'
            }),
            ...mapActions('productsegment',{
                getAllProductSegments: 'getAllProductSegments',
                resetAllProductSegments: 'resetAllProductSegments'
            }),
            ...mapActions('salescolleague', {
                getAllSalesColleagues: 'getAllSalesColleagues',
            }),
            ...mapActions('shippingport', {
                getAllShippingPorts: 'getAllShippingPorts',
            }),
            addBuyer () {
                this.validateContacts = true
                let _this = this
                setTimeout(function(){
                    if(_this.validContacts) {
                        if (_this.$refs.buyerProfileForm.validate()) {
                            _this.loading.add = true
                            _this.createBuyer()
                                .then((response) => {
                                    if (response.status == 'success') {
                                        _this.$toast.success(_this.$t('message.successes.buyerAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        _this.loading.add = false
                                        _this.newBuyerUnsaved = false
                                        _this.newBuyerSaved = true
                                        // reset new buyer state
                                        _this.resetCurrentBuyer().then(() => {
                                            //reset new contacts
                                            _this.resetAllContacts(_this.statePrefix)
                                                .then(() => {
                                                    //reset all buyers to add new buyer to list
                                                    _this.resetAllBuyers()
                                                        .then(() => {
                                                            // push new route
                                                            router.push({
                                                                name: 'update_customer',
                                                                params: {buyerId: response.buyer_id}
                                                            }).catch(err => {
                                                            })
                                                        })
                                                })
                                        })
                                    } else {
                                        _this.$toast.error(_this.$t('message.errors.buyerNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.loading.add = false
                                    }
                                }).catch(() => {
                                    _this.$toast.error(_this.$t('message.errors.buyerNotAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    _this.loading.add = false
                                })
                        } else {
                            // scroll to field
                            // this.$vuetify.goTo( this.$refs.useridField )
                            _this.$toast.error(_this.$t('message.errors.inputNotValid'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    } else {
                        _this.$toast.error(_this.$t('message.errors.inputNotValid'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                },1000)
            },
            addMarker() {
                if (this.map.currentPlace) {
                    this.Company__longitude = this.map.currentPlace.geometry.location.lng()
                    this.Company__latitude = this.map.currentPlace.geometry.location.lat()
                    let marker = {
                        lat: this.map.currentPlace.geometry.location.lat(),
                        lng: this.map.currentPlace.geometry.location.lng()
                    };
                    this.map.markers.push({ position: marker });
                    this.map.places.push(this.currentPlace);
                    this.map.center = marker
                    this.map.currentPlace = null
                }
            },
            closeConvertToSupplierDialog() {
                this.sameNameSuppliers = []
                this.convertToSupplerDialog = false
            },
            convertBuyerToSupplier() {
                let promise = new Promise( (resolve, reject) => {
                    api
                        .put('/customers/' + this.Customer__id + '/convert-supplier')
                        .then((response) => {
                            resolve(response.data.supplier_id)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                promise
                    .then((response) => {
                        this.$toast.success(this.$t('message.successes.customerConverted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.closeConvertToSupplierDialog()
                        router
                            .push({
                                name: 'update_supplier',
                                params: {supplierId: response}
                            })
                            .catch(err => {})
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.customerNotConverted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.closeConvertToSupplierDialog()
                    })
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterCreditLimitCurrencies() { //this is not good, please improve 2023-04-27
                if(this.Company__country_id == 1){
                    this.creditLimitCurrencies = [this.allCurrencies.find(c => c.Currency.code == 'RM')]
                    this.CreditLimit__currency_id = 1
                } else if(this.Company__country_id == 4) {
                    this.creditLimitCurrencies = [this.allCurrencies.find(c => c.Currency.code == 'IDR')]
                    this.CreditLimit__currency_id = 10
                }
                return true
            },
            geolocate () {
                navigator.geolocation.getCurrentPosition(position => {
                    console.log('Geolocating ...')
                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                })
            },
            geoLocate() {
                let address = ''
                if(this.Company__address1 != null && this.Company__address1.length > 0) address = address + this.Company__address1.trim()
                if(this.Company__address2 != null && this.Company__address2.length > 0) address = address + ', ' + this.Company__address2.trim()
                if(this.Company__address3 != null && this.Company__address3.length > 0) address = address + ', ' + this.Company__address3.trim()
                if(this.Company__postcode != null && this.Company__postcode.length > 0) address = address + ', ' + this.Company__postcode.trim()
                if(this.Company__city != null && this.Company__city.length > 0) address = address + ' ' + this.Company__city.trim()
                if(this.Company__state != null && this.Company__state.length > 0) address = address + ', ' + this.Company__state.trim()
                let countryObj = this.allCountries.find(c => c.Country.id == this.Company__country_id)
                if(countryObj) {
                    address = address + ', ' + countryObj.Country.name
                }
                if(address.length > 0){
                    this.$gmapApiPromiseLazy().then(() => {
                        const geocoder = new google.maps.Geocoder()
                        let _this = this
                        geocoder.geocode({ address }, function (results, status) {
                            if (status === 'OK') {
                                if(_this.Company__latitude != results[0].geometry.location.lat() && _this.Company__longitude != results[0].geometry.location.lng()) {
                                    _this.Company__latitude = results[0].geometry.location.lat()
                                    _this.Company__longitude = results[0].geometry.location.lng()
                                    _this.map.center.lat = _this.Company__latitude
                                    _this.map.center.lng = _this.Company__longitude
                                    let marker = {
                                        lat: _this.Company__latitude,
                                        lng: _this.Company__longitude
                                    };
                                    _this.map.markers = []
                                    _this.map.markers.push({position: marker});

                                    //update latitude/longitude of company
                                    if (_this.updateMode) {
                                        let company = {
                                            latitude: _this.Company__latitude,
                                            longitude: _this.Company__longitude
                                        }
                                        api
                                            .put('/companies/' + _this.Company__id, {
                                                company: company,
                                            })
                                            .then(() => {
                                                _this.updatedBuyerUnsaved = false
                                                console.log('Buyer latitude and longitude has been updated.')
                                            })
                                            .catch(() => {
                                                _this.updatedBuyerUnsaved = false
                                                console.log('Cannot update latitude and longitude of buyer.')
                                            })
                                    }
                                }
                            } else {
                                _this.cannotGeoLocate = true
                            }
                        })
                    })
                } else {
                    this.cannotGeoLocate = true
                }
            },
            getBuyerContacts () {
                let company_id = this.Customer__company_id
                return new Promise((resolve, reject) => {
                    api
                        .get("/companies/" + company_id + "/contacts")
                        .then(response => {
                            this.buyerContacts = response.data.data
                            resolve('done');
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            resolve('done');
                        })
                })
            },
            getCreditLimit(amount, currencyId) {
                let creditLimit = this.$t('message.na')
                if(amount) creditLimit = numberFormat(amount,'0,0')
                if(currencyId){
                    const currency = this.allCurrencies.find(c => c.Currency.id == currencyId)
                    if(currency) creditLimit = creditLimit + ' ' + currency.Currency.code
                }
                return creditLimit
            },
            getNewOtsBuyerId: function() {
                if (this.Company__country_id != null) {
                    this.loading.newOtsBuyerId = true
                    return new Promise ((resolve, reject) => {
                        api
                            .get("/customers/userid",{
                                params: {
                                    country_code: this.allCountries.find((d)=>d.Country.id == this.Company__country_id)?.Country?.code
                                }
                            })
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.Customer__userid = response.data.data
                                } else if(response.data.status == 'error'){
                                    this.enterOtsBuyerIdManually = true
                                }
                                this.loading.newOtsBuyerId = false
                            })
                            .catch(error => {
                                reject(error);
                                this.$toast.error(this.$t('message.errors.noNewOtsBuyerId'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.newOtsBuyerId = false
                            })
                            .finally(()=>{
                                this.loading.newOtsBuyerId = false
                            });
                    })
                } else {
                    this.errors.missing_values = [] //empty errors first
                    if(this.Company__country_id == null){
                        this.errors.missing_values.push(this.$t('message.country'));
                    }
                    this.dialogs.error_message = this.$t('message.errors.cannotGetNewOtsBuyerId');
                    this.dialogs.error = true;
                }
            },
            getNewOtsBuyerPassword: function(){
                this.Customer__password = Math.floor(Math.random() * (9999-1000 + 1) + 1000)
            },
            loadAnotherBuyer (val) {
                router.push({name: 'update_customer', params: { buyerId : val}}).catch(err => {})
            },
            loadBuyerById (val) {
                let self = this
                this.loader = true
                this.getBuyerToUpdateById(val)
                    .then(()=>{
                        if(this.Company__latitude != '' && this.Company__longitude != '' && this.Company__longitude != null && this.Company__latitude != null) {
                            this.map.center.lat = this.Company__latitude
                            this.map.center.lng = this.Company__longitude
                            let marker = {
                                lat: this.Company__latitude,
                                lng: this.Company__longitude
                            };
                            this.map.markers = []
                            this.map.markers.push({ position: marker });

                        } else {
                            this.geoLocate()
                        }

                        if(this.CreditLimit__currency_id == null){
                            this.filterCreditLimitCurrencies()
                        }

                        let payload = {
                            company_id: this.Company__id,
                            prefix: self.statePrefix
                        }
                        this.getActiveContactsByCompany(payload)
                            .then(()=>{
                                let payload = {
                                    customer_id: this.Customer__id,
                                    prefix: self.statePrefix
                                }
                                this.getActiveAddressesByBuyer(payload)
                                    .then(()=>{
                                        this.updatedBuyerUnsaved = false;
                                        this.loader = false;
                                        this.getBuyerContacts()
                                            .then(()=>{
                                                this.buyerContacts.forEach(c => {
                                                    if(c.Contact.email != null && c.Contact.email != '') {
                                                        this.selectedBuyerContacts.push(c.Contact.email)
                                                    }
                                                })
                                            })
                                    })
                                    .catch(() => {
                                        this.updatedBuyerUnsaved = false;
                                        this.loader = false;
                                    })
                                    .then(() => {
                                        this.updatedBuyerUnsaved = false;
                                        this.loader = false;
                                    })
                            })
                            .catch(() => {
                                this.updatedBuyerUnsaved = false;
                                this.loader = false;
                            })
                            .then(() => {
                                this.updatedBuyerUnsaved = false;
                                this.loader = false;
                            })
                    })
                    .catch((error) => {
                        _this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.updatedBuyerUnsaved = false;
                        this.loader = false;
                    })
            },
            noCityCountries(countryId) {
                const countriesWithoutCities = [12, 27, 84]
                if(countriesWithoutCities.includes(countryId)) return true
                return false
            },
            openConvertToSupplierDialog() {
                let promise = new Promise((resolve, reject) => {
                    api
                        .get('/customers/' + this.Customer__id + '/check-supplier')
                        .then(response => {
                            if(response.data.status == 'error'){
                                this.sameNameSuppliers = response.data.suppliers
                            }
                            resolve('done')
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
                promise.then(() => {
                    this.convertToSupplerDialog = true
                })
            },
            async openSendLoginInfoDialog() {
                if(this.Customer__password != null && this.Customer__userid != null){
                    this.sendLoginInfoDialog = true
                } else {
                    this.dialogs.error_message = this.$t('message.buyerUseridPasswordMissing')
                    this.dialogs.error = true
                }
            },
            reloadContactList(){
                // this.pageKey = Math.floor(Math.random() * 100)
                let payload = {
                    company_id: this.Company__id,
                    prefix: this.statePrefix
                }
                this.getActiveContactsByCompany(payload)
            },
            async resetBuyerForm() {
                if (await this.$root.$confirm(this.$t('message.resetBuyerForm'), this.$t('message.confirmations.continueBuyerFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentBuyer()
                        .then(()=>{
                            let _this = this
                            setTimeout(() => {
                                _this.newBuyerSaved = false
                                _this.newBuyerUnsaved = false
                                _this.loading.reset = false
                                _this.$toast.success(this.$t('message.buyerFormResetNotification'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            }, 500)
                        })
                }
            },
            saveBuyer () {
                this.validateContacts = true
                let _this = this
                setTimeout(function() {
                    if(_this.validContacts) {
                        if (_this.$refs.buyerProfileForm.validate()) {
                            _this.loading.save = true
                            _this.updateBuyer(_this.Customer__id)
                                .then((status) => {
                                    if (status == 'done') {
                                        _this.$toast.success(_this.$t('message.successes.buyerUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        let payload = {
                                            company_id: _this.Company__id,
                                            prefix: _this.statePrefix
                                        }
                                        _this.getActiveContactsByCompany(payload)
                                            .then(() => {
                                                let payload = {
                                                    customer_id: _this.Customer__id,
                                                    prefix: _this.statePrefix
                                                }
                                                _this.getActiveAddressesByBuyer(payload)
                                                    .then(() => {
                                                        _this.updatedBuyerUnsaved = false;
                                                        _this.loader = false;

                                                        _this.getBuyerContacts()
                                                            .then(() => {
                                                                _this.selectedBuyerContacts = [];
                                                                _this.buyerContacts.forEach(c => {
                                                                    if (c.Contact.email != null && c.Contact.email != '') {
                                                                        _this.selectedBuyerContacts.push(c.Contact.email)
                                                                    }
                                                                })
                                                            })

                                                    })
                                                    .catch(() => {
                                                        _this.updatedBuyerUnsaved = false;
                                                        _this.loader = false;
                                                    })
                                                    .then(() => {
                                                        _this.updatedBuyerUnsaved = false;
                                                        _this.loader = false;
                                                    })
                                            })
                                            .catch(() => {
                                                _this.updatedBuyerUnsaved = false;
                                                _this.loader = false;
                                            })
                                            .then(() => {
                                                _this.updatedBuyerUnsaved = false;
                                                _this.loader = false;
                                            })
                                    } else {
                                        _this.$toast.error(_this.$t('message.errors.buyerNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.loading.save = false
                                    }
                                })
                                .catch(() => {
                                    _this.$toast.error(_this.$t('message.errors.buyerNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    _this.loading.save = false
                                })
                                .finally(() => {
                                    _this.loading.save = false
                                })
                        } else {
                            _this.$toast.error(_this.$t('message.errors.requiredFieldsEmpty'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                },1000)
            },
            setPlace (place) {
                this.map.currentPlace = place
            },
            sendLoginInfoToBuyer () {
                if(this.selectedBuyerContacts.length == 0){
                    this.dialogs.error_message = this.$t('message.noContactsSelected')
                    this.dialogs.error = true
                } else {
                    this.loading.send = true
                    let payload = {
                        buyerId: this.Customer__id,
                        contacts: this.selectedBuyerContacts,
                        bccSales: this.Salescontact__bcc
                    }
                    this.sendOtsLoginInfoToBuyer(payload)
                        .then((status)=>{
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.otsLoginInfoSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            } else {
                                this.$toast.error(this.$t('message.errors.otsLoginInfoNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.otsLoginInfoNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.send = false
                            this.sendLoginInfoDialog = false
                        })
                }
            },
            async suspendBuyer (val) {
                if (await this.$root.$confirm(this.$t('message.suspendBuyer'), this.$t('message.confirmations.continueBuyerSuspendAction'), {color: 'orange'})) {
                    this.loading.suspend = true
                    this.suspendBuyerById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.buyerSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetAllBuyers()
                            this.getAllActiveBuyers()
                            router.push({name: 'customer_listing'}).catch(err => {})
                            this.loading.suspend = false
                        } else {
                            this.$toast.error(this.$t('message.errors.buyerNotSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            updateHistoryKey () {
                this.historyKey = Math.random() * (1000 - 1) + 1
            },
            setItemsValidity(val){
                this.validContacts = val
                this.validateContacts = false
            },
        },
        watch: {
            Customer__title(val) {
                this.Company__name = val
            },
            Company__address1() {
                this.geoLocate()
            },
            Company__address2() {
                this.geoLocate()
            },
            Company__address3() {
                this.geoLocate()
            },
            Company__country_id() {
                this.geoLocate()
            },
            Company__city() {
                this.geoLocate()
            },
            Company__postcode() {
                this.geoLocate()
            },
            Company__state() {
                this.geoLocate()
            },
        },
        created(){
            // this.resetAllProductSegments()
            if(this.allProductSegments.length == 0) this.getAllProductSegments()
            if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
            if(this.allCountries.length == 0) this.getAllCountries()
            if(this.allShippingDischargePorts.length == 0) this.getAllShippingPorts()
            if(this.allBuyerPaymentTerms) this.getAllPaymentTerms()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()

            if(this.$route.matched.some(({name}) => name === 'update_customer')){
                this.loadBuyerById(this.buyerId)
            } else if(this.$route.matched.some(({name}) => name === 'add_customer')){
                this.newBuyerUnsaved = false
            }
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.buyer.update.Company
                },
                () => {
                    this.updatedBuyerUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.buyer.current.Company
                },
                () => {
                    this.newBuyerUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.buyer.update.Customer
                },
                () => {
                    this.updatedBuyerUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.buyer.current.Customer
                },
                () => {
                    this.newBuyerUnsaved = true
                },
                { deep: true }
            )

            if(this.$route.matched.some(({name}) => name === 'update_customer')){
                setInterval(() => {
                    this.$title =  this.Customer__otsname
                }, 2000)
            } else {
                this.$title =  this.$t('message.titles.newBuyer')
                //set several properties if role is SALES
                if(['ROLE_SALES','ROLES_COUNTRY_MANAGER'].includes(this.User.role)){
                    this.allSalesColleagues.forEach(s => {
                        if(s.Salesperson.userid == this.User.email) {
                            this.Customer__salescontact_id = s.Salesperson.id
                            this.Company__country_id = this.User.country_id
                        }
                    })
                }
            }
        }
    }
</script>

<style>
.field-width-50 {
    width: 50px !important;
}
.field-width-150 {
    width: 150px !important;
}
.force-text-left .v-text-field__slot input {
    text-align: left !important;
}
.v-dialog {
    min-width: 200px !important;
}
.wrap-text {
    white-space: normal;
}
.font-xs {
    font-size: small;
}
</style>
